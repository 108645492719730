const baseUrl = 'https://adm.disaica.us/';
const consumer_key_gf = 'ck_0061a3af91969e7a5d38308db3dd445b52de79af';
const secret_key_gf = 'cs_cb931945b89debd08c1b65a22679fe9c4ea55dfb';

const navItems = [
    {
      title: "Home",
      link: "/",
      subLinks: [],
    },
    {
      title: "About",
      link: "/about",
      subLinks: [],
    },
    {
      title: "Services",
      link: "/services",
      subLinks: [],
    },
    {
      title: "Portfolio",
      link: "/portfolio",
      subLinks: []
    },
    {
      title: "Contact",
      link: "/contact",
      subLinks: [],
    },
  ];

export {
    baseUrl,
    navItems,
    consumer_key_gf,
    secret_key_gf
}