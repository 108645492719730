import { baseUrl } from "./constants";

const getServiceList = async () => {
  try {
    const endpoint = `${baseUrl}/wp-json/wp/v2/service`;
    const options = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    const response = await fetch(endpoint, options);
    // console.log(response)
    if (!response.ok) {
      // Throw an error for the calling function to handle
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    // console.log(data)

    const serviceList = data.map((elem: any) => ({
      id: elem.id,
      name: elem.title.rendered.replace('&amp;', '&').replace('&#038;', "&"),
    }));
    // console.log(serviceList)

    return serviceList;
  } catch (error) {
    console.error('Error fetching service list:', error);
    // Depending on your error handling strategy, you may want to return an error
    // indicator, throw the error, or return an empty array as a fallback, etc.
    return [];
  }
};

  const getServiceNavbar = async () => {
    const endpoint = `${baseUrl}/wp-json/wp/v2/service?per_page=100`;
    const options = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    
    const response = await fetch(endpoint, options);
    const data: any = await response.json();

    const serviceList = data.map((elem: any) => ({
      id: elem.id,
      name: elem.name.replace('&amp;', '&'),
      slug: elem.slug,
    }));

    return serviceList;
  };

const getSingleService = async (serviceId: any) => {
  const endpoint = `${baseUrl}/wp-json/wp/v2/service?id=${serviceId}`;
  const options = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  const response = await fetch(endpoint, options);
  const data: any = await response.json();

  const service = {
    id: data[0].id,
    name: data[0].name.replace('&amp;', '&'),
    description: data[0].description,
    slug: data[0].slug,
  };

  return service;
};


export {
    getServiceList,
    getServiceNavbar,
    getSingleService
  };