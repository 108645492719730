import React, { useState, useEffect } from "react";
import { Form } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
import { baseUrl } from "../src/api/constants";
import { getServiceList } from "../src/api/services";
import "./LoadingStyles.css";
import { sendTypeForm } from "api/forms";

registerCoreBlocks();

const App = () => {

  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [error, setError] = useState(null);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const serviceList = await getServiceList();
        setServices(serviceList);
      } catch (err) {
        // setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchServices();
  }, []);
  if (isLoading) {
    return (
      <div className="loadingContainer">
        {/* <img
          src={`${baseUrl}/wp-content/uploads/2023/10/LLC-FONDO-BLANCO-3-Traced-1.png`}
          alt="Loading"
          className="loadingImage"
        /> */}
        <img
          src={`${baseUrl}/wp-content/uploads/2024/04/Logo-Disaica-LLC-Negativo.png`}
          alt="Loading"
          className="loadingImage"
        />
        <div className="loadingTextWithDots">
          Loading
          <div className="dotsContainer">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        </div>
      </div>
    );
  }

  // if (error) {
  //   return <div className="errorContainer">Error: {error.message}</div>;
  // }

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <Form
        formId={1}
        formObj={{
          blocks: [
            {
              name: "welcome-screen",
              id: "welcome",
              attributes: {
                label: "Transform your urban development concepts into executed plans with our specialized engineering solutions.",
                // description: "This is just a description",
                attachment: {
                  type: "image",
                  url: `${baseUrl}/wp-content/uploads/2024/04/Logo-Disaica-LLC-Negativo.png`,
                },
              },
            },
            {
              name: "short-text",
              id: "name",
              attributes: {
                required: true,
                label: "Let's start with your full name",
              },
            },
            {
              name: "email",
              id: "email",
              attributes: {
                required: true,
                label:
                  "Thanks {{field:name}}, please insert your email to keep in touch.",
              },
            },
            {
              name: "number",
              id: "contact",
              attributes: {
                required: true,
                label: "Please insert your contact number. (International format, only numbers)",
              },
            },
            {
              name: "short-text",
              id: "company",
              attributes: {
                required: true,
                label: "What's your company name?",
              },
            },
            {
              name: "multiple-choice",
              id: "service",
              attributes: {
                required: true,
                multiple: false,
                verticalAlign: false,
                label: "Which service are you interested in?",
                choices: services.map((service) => {
                  return {
                    label: service.name,
                    value: service.name,
                  };
                }),
              },
            },
            {
              name: "short-text",
              id: "location",
              attributes: {
                required: true,
                label:
                  "What is the location of your project? (City, State, Country)",
              },
            },
            {
              name: "short-text",
              id: "size",
              attributes: {
                required: true,
                label:
                  "What is the estimated size of the project area? (Square footage or other relevant metric)",
              },
            },
            {
              name: "date",
              id: "timeline",
              attributes: {
                required: true,
                label:
                  "What is the timeline you are looking at for project completion?",
              },
            },
            {
              name: "long-text",
              id: "description",
              attributes: {
                required: true,
                label: "Can you provide a brief description of your project?",
              },
            },
            {
              name: "date",
              id: "meet",
              attributes: {
                required: true,
                label:
                  "Would you like to schedule a consultation meeting to discuss your project in detail?",
              },
            },
            {
              name: "statement",
              id: "confirmation",
              attributes: {
                label:
                  "Thank you for the information! We will get back to you in a few days.",
                buttonText: "Continue",
                quotationMarks: true,
              },
            },
          ],
          settings: {
            animationDirection: "vertical",
            disableWheelSwiping: false,
            disableNavigationArrows: false,
            disableProgressBar: false,
          },
          theme: {
            font: "Roboto",
            buttonsBgColor: "#868686",
            logo: {
              src: "",
            },
            backgroundColor: "#000",
            questionsColor: "#fff",
            answersColor: "#0aa7c2",
            buttonsFontColor: "#fff",
            buttonsBorderRadius: 25,
            errorsFontColor: "#fff",
            errorsBgColor: "#f00",
            progressBarFillColor: "#000",
            progressBarBgColor: "#ccc",
          },
        }}
        onSubmit={(data, { completeForm, setIsSubmitting }) => {
          console.log("Form submitted with data: ", data);
          setIsSubmitting(true); // Start the form submission process

          // Format the data as needed by the sendTypeForm API function
          const formattedData = {
            name: data.answers.name.value,
            email: data.answers.email.value,
            contact: JSON.stringify(data.answers.contact.value),
            service: data.answers.service.value[0],
            location: data.answers.location.value,
            size: data.answers.size.value,
            timeline: data.answers.timeline.value,
            description: data.answers.description.value,
            meet: data.answers.meet.value,
          };

          sendTypeForm(formattedData)
            .then((response) => {
              setIsSubmitting(false); // Form submission is complete
              console.log("Form submitted successfully:", response);

              if (response === "OK" || response === "Created") {
                completeForm();
                // Optional: Add analytics or custom callback here
                // analytics.track('Form Submitted', {...data});
              } else {
                // Handle server-side validation errors or other responses
                // showError(response || 'Server validation failed');
              }
            })
            .catch((error) => {
              setIsSubmitting(false);
              console.error(
                "There was a problem with the form submission:",
                error
              );
              // Display a user-friendly error message
              // showError('An error occurred while submitting the form. Please try again.');
            });
        }}
      />
    </div>
  );
};

export default App;
