import { baseUrl, consumer_key_gf, secret_key_gf } from "./constants";
import { Buffer } from "buffer";

const submitFormById = async (formId: string, data: any) => {
  const endpoint = `${baseUrl}/wp-json/gf/v2/forms/${formId}/submissions/`;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(endpoint, options);
  const json = await response.json();
  return json;
};

// Function to fetch the form structure by formId
const fetchFormStructureById = async (formId: string): Promise<any> => {
  const endpoint = `${baseUrl}/wp-json/gf/v2/forms/${formId}`;
  const consumerKey = consumer_key_gf;
  const consumerSecret = secret_key_gf;
  const authString = `${consumerKey}:${consumerSecret}`;
  const base64Auth = Buffer.from(authString).toString("base64"); // Encoding to Base64

  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${base64Auth}`,
    },
  };

  try {
    const response = await fetch(endpoint, options);

    if (!response.ok) {
      const error = await response.json();
      throw new Error(`Error: ${error.message}`);
    }

    const json = await response.json();
    console.log("Form structure fetched successfully:", json);
    return json;
  } catch (error) {
    console.error("Fetching form structure failed:", error);
    throw error;
  }
};

const sendTypeForm = async (data: any) => {
  const endpoint = `${baseUrl}/wp-json/gf/v2/forms/4/submissions`;
  const authString = `${consumer_key_gf}:${secret_key_gf}`;
  const base64Auth = Buffer.from(authString).toString("base64");
  const FormData = {
    input_20: data.name,
    input_19: data.email,       // Assuming input_2 is for the email
    input_18: data.contact,     // Assuming input_3 is for the contact
    input_11: data.service,     // and so on for the rest of the fields
    input_12: data.location,
    input_13: data.size,
    input_15: data.timeline,
    input_16: data.description,
    input_17: data.meet        // Make sure the input numbers align with your form's fields
};
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${base64Auth}`,
    },
    body: JSON.stringify(FormData),
  };

  try {
    const sent = await fetch(endpoint, options);
    const response = sent.statusText;
    console.log(response);
    return response;
  } catch (error: any) {
    console.log(error);
    return error;
  }
};

export { submitFormById, fetchFormStructureById, sendTypeForm };
